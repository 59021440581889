import { Link } from "gatsby-interface"
import React from "react"

const listCss = theme => ({
  margin: 0,
  padding: 0,
  listStyleType: "none",
  display: "flex",
  flexDirection: "row",
  borderTop: `1px solid ${theme.colors.blackFade[10]}`,
  borderBottom: `1px solid ${theme.colors.blackFade[10]}`,
  whiteSpace: "nowrap",
  overflowX: "scroll",
})

const listItemCss = theme => ({
  padding: `0 ${theme.space[5]}`,

  "&:first-of-type": {
    paddingLeft: 0,
  },

  margin: 0,
})

const linkCss = theme => ({
  fontSize: theme.fontSizes[1],
  height: "64px",
  color: theme.colors.grey[70],

  "&:focus": {
    textDecoration: "unset",
    color: theme.colors.purple[60],
    borderBottom: `2px solid ${theme.colors.purple[60]}`,
  },
})

export const PluginSectionNav = ({ children }) => {
  return (
    <nav>
      <ul css={listCss}>{children}</ul>
    </nav>
  )
}

export const PluginSectionNavItem = ({ children, to }) => {
  return (
    <li css={listItemCss}>
      <Link to={to} variant="SIMPLE" css={linkCss}>
        {children}
      </Link>
    </li>
  )
}
