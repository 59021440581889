import React from "react"
import { BackgroundSvg } from "../components/background-svg"

const BLOCK_WIDTH = "644px"

const wrapperCss = theme => ({
  padding: theme.space[7],

  [theme.mediaQueries.desktop]: {
    padding: `0 ${theme.space[10]}`,
  },
})

const headerContainerCss = {
  position: "relative",
}

const headerCss = {
  maxWidth: BLOCK_WIDTH,
}

const titleCss = theme => ({
  marginBottom: theme.space[8],
})

const subtitleCss = theme => ({ marginBottom: theme.space[5] })

const headingCTAsCss = theme => ({
  paddingBottom: theme.space[8],
})

export const pluginCtasCss = {
  display: "flex",
  flexDirection: "row",
  listStyleType: "none",
  margin: 0,
  padding: 0,
}

const backgroundCss = theme => ({
  position: "absolute",
  right: theme.space[10],
  bottom: 0,
  height: "320px",
})

export const pluginCtaCss = theme => ({
  marginRight: theme.space[7],
})

export const navCss = theme => ({
  marginBottom: theme.space[8],
  position: "relative",
  zIndex: 2,
  background: theme.colors.white,
})

const footerCss = theme => ({
  margin: `${theme.space[10]} 0`,
})

export const PluginsLayout = ({
  Title,
  Subtitle,
  HeadingCTAs,
  Nav,
  Content,
  Footer,
}) => {
  return (
    <div css={wrapperCss}>
      <div css={headerContainerCss}>
        <div css={headerCss}>
          <div css={titleCss}>{Title}</div>
          <div css={subtitleCss}>{Subtitle}</div>
          <div css={headingCTAsCss}>{HeadingCTAs}</div>
        </div>

        <div css={backgroundCss}>
          <BackgroundSvg />
        </div>
      </div>

      <div css={navCss}>{Nav}</div>

      <div>{Content}</div>

      <div css={footerCss}>{Footer}</div>
    </div>
  )
}
